<template>
  <div class="teacher">
    <div class="content1" />
    <div class="content2">
      <div class="title">
        口碑师资一路引领 指点迷津助力梦想
        <div class="bg" />
      </div>
      <img class="teacher2" src="@/style/image/teacher/teacher2.png">
      <img class="teacher3" src="@/style/image/teacher/teacher3.png">
      <div class="title" style="margin-top: 120px">
        为人才赋予能量 为梦想保驾护航
        <div class="bg" />
      </div>
      <div class="menu-list">
        <div v-for="(item, inded) in list" :key="inded" class="menu-item">
          <img class="menu-img" :src="item.img">
          <div class="menu-text">{{ item.text }}</div>
        </div>
      </div>
    </div>
    <div class="content3">
      <div class="title">
        传道授业解惑 提灯照亮未来
        <div class="bg" />
      </div>
      <div class="teacer-list">
        <div v-for="(item, inded) in teacherList" :key="inded" class="teacer-item">
          <img class="teacer-img" :src="item.terImg">
          <div class="teacer-cont">
            <div class="nickname">{{ item.nickname }}</div>
            <div class="majorName">{{ item.majorName }}</div>
            <div class="profile fireElp" v-html="item.profile" />
            <div class="ter-but" @click="juxueHand()">点击查看详情 ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="content4">
      <div class="title">
        良师开卷 益友同行
        <div class="bg" />
      </div>
      <div class="teacer-list">
        <div v-for="(item, inded) in teacherList2" :key="inded" class="teacer-item">
          <div class="flex">
            <img class="teacer-img" :src="item.terImg">
            <div class="teacer-cont">
              <div class="nickname">{{ item.nickname }}</div>
              <div class="majorName">{{ item.majorName }}</div>
            </div>
          </div>
          <div class="profile twoEllipsis" v-html="item.profile" />
          <div class="ter-but" @click="juxueHand()">点击查看详情 ></div>
        </div>
      </div>
      <!--  -->
      <div class="title" style="margin-top: 120px">
        聚学U行业升级落地交流沙龙会
        <div class="bg" />
      </div>
      <img class="teacher4" src="@/style/image/teacher/isteacher4.png">
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [
        { img: require(`@/style/image/teacher/menu1.png`), text: '行业师资' },
        {
          img: require(`@/style/image/teacher/menu2.png`),
          text: '专职教学团队'
        },
        {
          img: require(`@/style/image/teacher/menu3.png`),
          text: '教研中心'
        },
        {
          img: require(`@/style/image/teacher/menu4.png`),
          text: '专业录课基地'
        },
        { img: require(`@/style/image/teacher/menu5.png`), text: '教学辅导' },
        { img: require(`@/style/image/teacher/menu7.png`), text: '在线答疑' },
        { img: require(`@/style/image/teacher/menu8.png`), text: '高清录播' },
        { img: require(`@/style/image/teacher/menu9.png`), text: '互动直播' },
        {
          img: require(`@/style/image/teacher/menu10.png`),
          text: '教辅资料研发'
        },
        {
          img: require(`@/style/image/teacher/menu11.png`),
          text: '题库汇总'
        }
      ],
      teacherList: [
        {
          terImg: require(`@/style/image/teacher/terImg2.png`),
          nickname: '李老师',
          majorName: '自学考试辅导',
          profile:
            '法学硕士，风格稳重、可靠，又不失轻松，把复杂的法学知识案例化，通俗易懂。讲保险法、中国法律思想史等法学专业课程'
        },
        {
          terImg: require(`@/style/image/teacher/terImg1.png`),
          nickname: '谢老师',
          majorName: '自学考试辅导',
          profile: '国贸专业，具有多年得授课经验，授课风格幽默有趣，专业知识扎实，深受学员好评'
        },
        {
          terImg: require(`@/style/image/teacher/terImg3.png`),
          nickname: '郭老师',
          majorName: '成考考前辅导、自学考试辅导',
          profile:
            '毕业于北京师范大学，哲学硕士。主讲课程：考研政治、自考政治、《当代中国政治制度》 等，教学风格幽默风趣、有六年的教学经验。'
        },
        {
          terImg: require(`@/style/image/teacher/terImg4.png`),
          nickname: '苗老师',
          majorName: '自学考试辅导',
          profile:
            '管理学博士，具有丰富的专业知识，对教材内容非常熟悉，授课内容做到取其精华，可以更好的把握考点，直击考试'
        }
      ],
      teacherList2: [
        {
          terImg: require(`@/style/image/teacher/terImg5.png`),
          nickname: '曹老师',
          majorName: '自学考试辅导',
          profile: '金牌讲师，授课经验丰富，专业知识强，上课具有吸引力，采用有趣的教学方法，快速的记忆知识'
        },
        {
          terImg: require(`@/style/image/teacher/terImg6.png`),
          nickname: 'Sissi老师',
          majorName: '自学考试辅导',
          profile:
            '毕业于英国桑德兰大学，对于新闻有独特的见解，敏感度较高，对于西方新闻界也比较了解，专业能力较强，是我们的新闻学的金牌讲师。'
        },
        {
          terImg: require(`@/style/image/teacher/terImg7.png`),
          nickname: '王老师',
          majorName: '自学考试辅导',
          profile:
            '毕业于暨南大学工商管理专业，硕士学历，讲师授课风格受欢迎，通过率高，专业度强，授课经验丰富。'
        },
        {
          terImg: require(`@/style/image/teacher/terImg8.png`),
          nickname: '马老师',
          majorName: '自学考试辅导',
          profile:
            '金牌讲师、文学硕士  ，外国语言学及应用语言学专业，六年英语从教经验   主讲课程：成考高起点、专升本英语 、自考英语、同等学力申硕'
        }
      ]
    }
  },
  async created() {},
  mounted: {}
}
</script>
<style lang="less" scoped>
.teacher {
  width: 100%;
  background: #fff;
  padding-bottom: 120px;
  /* 标题 */
  .title {
    width: 100%;
    text-align: center;
    font-size: 48px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 48px;
    height: 58px;
    position: relative;
    .bg {
      width: 320px;
      height: 24px;
      background: #00ab8c;
      border-radius: 0px 0px 0px 0px;
      opacity: 0.2;
      position: absolute;
      bottom: 0px;
      left: calc(50% - 160px);
    }
  }
  .content1 {
    width: 100%;
    height: 480px;
    background: url("~@/style/image/teacher/isteacherBg2.png");
    background-size: 100% 100%;
  }
  .content2 {
    width: 100%;
    height: calc(540px + 776px);
    background: url('~@/style/image/teacher/teacherBg2.png') no-repeat;
    background-size: 100% 540px;
    padding-top: 60px;
    .teacher2 {
      width: 1080px;
      height: 510px;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      margin: 60px auto 0px;
    }
    .teacher3 {
      width: 1080px;
      // height: 110px;
      margin: -80px auto 0px;
    }
    .menu-list {
      width: calc(1080px + 106px);
      margin: 0px auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .menu-item {
        margin: 60px auto 0px;
        width: 20%;
        .menu-img {
          width: 110px;
          height: 110px;
          background: #fff;
          opacity: 1;
          margin: auto;
        }
        .menu-text {
          margin-top: 16px;
          font-size: 24px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: 24px;
          text-align: center;
        }
      }
    }
  }
  /* 讲师 */
  .teacer-list {
    width: 1080px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0px auto;
    .teacer-item {
      width: 50%;
      margin-top: 60px;
      padding-right: 60px;

      .nickname {
        font-size: 24px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        line-height: 24px;
        padding-top: 12px;
      }
      .majorName {
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 14px;
        margin-top: 16px;
      }
      .profile {
        margin-top: 20px;
        font-size: 16px;
        height: 112px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 28px;
      }
      .ter-but {
        width: 190px;
        height: 44px;
        background: linear-gradient(140deg, #2ad7b7 0%, #00cba6 100%);
        box-shadow: 0px 4px 12px 1px rgba(0, 191, 157, 0.3);
        border-radius: 22px 22px 22px 22px;
        opacity: 1;
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 44px;
        text-align: center;
        margin-top: 9px;
        cursor: pointer;
      }
    }
  }
  .content3 {
    width: 100%;
    background: url('~@/style/image/teacher/teacherBg3.png') no-repeat;
    background-size: 100% 760px;
    padding-top: 120px;
    .teacer-list {
      .teacer-item {
        display: flex;
        .teacer-img {
          width: 180px;
          height: 270px;
          border-radius: 8px 8px 8px 8px;
          opacity: 1;
          flex-shrink: 0;
          margin-right: 20px;
        }
        .teacer-cont {
          width: calc(100% - 20px - 180px);
        }
      }
    }
  }
  .content4 {
    width: 100%;
    background: url('~@/style/image/teacher/teacherBg4.png') no-repeat;
    background-size: 100% 760px;
    background-position: 0px 544px;
    padding-top: 120px;
    .teacer-list {
      .teacer-item {
        .teacer-img {
          width: 84px;
          height: 84px;
          background: #c4c4c4;
          border-radius: 8px 8px 8px 8px;
          opacity: 1;
          flex-shrink: 0;
          margin-right: 12px;
        }
        .teacer-cont {
          width: calc(100% - 20px - 180px);
          .nickname {
            padding-top: 17px;
          }
        }
        .profile {
          margin-top: 12px;
          height: 52px;
        }
      }
    }
    .teacher4 {
      width: 1080px;
      height: 630px;
      margin: 60px auto 0px;
    }
  }
}
</style>
